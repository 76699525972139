.loader {
    margin: 40px auto;
}

.nav-link {
    cursor: pointer;
}

.cb-text-featured {
    position: absolute;
    background: white;
    bottom: 19px;
    right: 15px;
    font-size: 12px;
    padding: 7px;
    border-radius: 29px;
    font-weight: 900;
}

select,
input[type=text],
input[type=password] {
    background-color: white !important;
}

.yellow-theme-color {
    color: #f7c600 !important;
}

.cb-elipse-text {
    position: relative;
    width: 100px;
    top: 65px;
    height: 88px;
    font-size: 26px;
    font-weight: 900;
    color: white;
    left: 67px;
}

.activated-txt-cb {
    font-size: 25px;
    margin: 53px;
    color: black;
    font-weight: 800;
}

.cb-elipse {
    border-radius: 150px;
    width: 250px;
    height: 250px;
    background: #41444B;
}

.MuiMenu-paper {
    right: 44px !important;
    opacity: 1;
    transform: none;
    transition: opacity 251ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 167ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    top: 69px !important;
    transform-origin: -16px 26px;
    left: unset !important;
}

.card-product-grid {
    height: 280px !important;
}

.shop-title {
    position: absolute;
    bottom: 4px;
    left: 13px;
    color: black;
    font-size: 21px;
}

.cat-img {
    height: 125px;
}

.cat-list-div {
    position: relative;
    padding: 5px;
}

.featured_headline_txt {
    font-weight: 600;
}

.feature-figure {
    position: absolute;
    width: 50px;
    top: 13px;
    height: 50px !important;
    right: 13px;
}

.margin-row-0 {
    margin-right: 0px !important;
    margin-left: 0px !important;
}

.featured-img-main {
    height: 230px;
    width: 100%;
    margin-bottom: 8px;
}

.cat-img-title {
    font-weight: 600;
}

.modal-header {
    border-bottom: 0px !important
}

.cat-div-text {
    bottom: 7px;
    position: absolute;
    left: 9px;
    color: white;
    font-size: 15px;
    font-weight: 600;
}

.slick-next:before,
.slick-prev:before {
    font-size: 0px !important;
    line-height: 0 !important;
    opacity: 0 !important;
}

.confirm-error {
    color: #f7c600;
    font-weight: 600;
}

.confirm-error-link {
    text-decoration: underline;
    color: blue;
    cursor: pointer;
}

.refer-code-text {
    font-weight: 900;
    color: #f3c800;
}

.loginbtn-white {
    cursor: pointer;
    color: white !important;
}

.nameText,
.down-arrow {
    cursor: pointer;
}

.MuiAvatar-colorDefault {
    background: orange !important;
    color: white !important;
}

.email-btn {
    width: 45% !important;
}

.email-btn2 {
    float: right !important;
}


/*Login email start*/

.loginsection2 {
    margin-top: 5px !important;
}

.loginsection2 a {
    color: black;
    display: inline-block;
    text-decoration: none;
    font-weight: 400;
}

.loginsection2 h2 {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    display: inline-block;
    margin: 40px 8px 10px 8px;
    color: #cccccc;
    cursor: pointer;
    padding-bottom: 4px;
}

.login-logo {
    width: 50%;
    margin: 20px;
}


/* STRUCTURE */

.loginsection2 .wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    min-height: 100%;
    padding: 20px;
}

#formContent {
    -webkit-border-radius: 10px 10px 10px 10px;
    border-radius: 10px 10px 10px 10px;
    background: #fff;
    padding: 30px;
    width: 90%;
    max-width: 450px;
    position: relative;
    padding: 0px;
    -webkit-box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
    text-align: center;
}

#formFooter {
    background-color: #f6f6f6;
    border-top: 1px solid #dce8f1;
    padding: 25px;
    text-align: center;
    -webkit-border-radius: 0 0 10px 10px;
    border-radius: 0 0 10px 10px;
}


/* TABS */

.loginsection2 h2.inactive {
    color: #cccccc;
}

.loginsection2 h2.active {
    color: Black;
    border-bottom: 2px solid #f7c600;
}


/* FORM TYPOGRAPHY*/

input[type=button],
input[type=submit],
input[type=reset] {
    background-color: black;
    border: none;
    color: white;
    padding: 15px 40px;
    font-weight: 900;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    text-transform: uppercase;
    font-size: 13px;
    -webkit-border-radius: 5px 5px 5px 5px;
    border-radius: 5px 5px 5px 5px;
    margin: 5px 20px 40px 20px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

input[type=button]:hover,
input[type=submit]:hover,
input[type=reset]:hover {
    background-color: rgb(247, 198, 0);
}

input[type=button]:active,
input[type=submit]:active,
input[type=reset]:active {
    -moz-transform: scale(0.95);
    -webkit-transform: scale(0.95);
    -o-transform: scale(0.95);
    -ms-transform: scale(0.95);
    transform: scale(0.95);
}

select,
input[type=text],
input[type=password] {
    background-color: #f6f6f6;
    border: none;
    color: #0d0d0d;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 5px;
    width: 85%;
    border: 2px solid #f6f6f6;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    -webkit-border-radius: 5px 5px 5px 5px;
    border-radius: 5px 5px 5px 5px;
}

input[type=text]:focus {
    background-color: #fff;
    border-bottom: 2px solid #5fbae9;
}

input[type=text]:placeholder {
    color: #cccccc;
}


/* ANIMATIONS */


/* Simple CSS3 Fade-in-down Animation */

.fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@-webkit-keyframes fadeInDown {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@keyframes fadeInDown {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

.black-color {
    color: black !important;
}


/* Simple CSS3 Fade-in Animation */

@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.fadeIn {
    opacity: 0;
    -webkit-animation: fadeIn ease-in 1;
    -moz-animation: fadeIn ease-in 1;
    animation: fadeIn ease-in 1;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-duration: 0.1s;
    -moz-animation-duration: 0.1s;
    animation-duration: 0.1s;
}

.fadeIn.first {
    -webkit-animation-delay: 0.1s;
    -moz-animation-delay: 0.1s;
    animation-delay: 0.1s;
}

.success-msg-login {
    text-align: center;
    font-weight: 600;
    color: green;
}

.error-msg-login {
    text-align: center;
    font-weight: 600;
    color: red;
}

.fadeIn.second {
    -webkit-animation-delay: 0.1s;
    -moz-animation-delay: 0.1s;
    animation-delay: 0.1s;
}

.fadeIn.third {
    -webkit-animation-delay: 0.1s;
    -moz-animation-delay: 0.1s;
    animation-delay: 0.1s;
}

.fadeIn.fourth {
    -webkit-animation-delay: .1s;
    -moz-animation-delay: .1s;
    animation-delay: .1s;
}


/* Simple CSS3 Fade-in Animation */

.underlineHover:after {
    display: block;
    left: 0;
    bottom: -10px;
    width: 0;
    height: 2px;
    background-color: #f7c600;
    content: "";
    transition: width 0.1s;
}

.srv-validation-message {
    color: red;
}

.underlineHover:hover {
    color: black !important;
}

.underlineHover:hover:after {
    width: 100%;
}

select {
    text-align: center;
    text-align-last: center;
    /* webkit*/
}

option {
    text-align: left;
    /* reset to left*/
}


/* OTHERS */

*:focus {
    outline: none;
}

#icon {
    width: 60%;
}

* {
    box-sizing: border-box;
}


/*Login email end*/

.coupon-modal-box {
    margin: 12px auto;
    width: 300px;
    background: grey;
}

.coupon-modal-head {
    background: #FDDB3A;
    font-weight: 600;
    padding: 5px;
}

.coupon-code-modal {
    background: #F6F6F6;
    color: black;
    font-size: 20px;
    font-weight: 600;
    padding: 18px 25px;
    border-bottom: 16px #FDDB3A solid;
}

.modal-body {
    font-weight: 800;
    text-align: center;
    color: black
}

.coupon-modal-img {
    width: 250px
}

.copybtn-coupon-modal {
    color: black;
    font-weight: 800;
    padding: 8px 20px;
    background: #FDDB3A;
    width: 300px;
    margin: 6px;
}

.coupon-modal-desc {
    font-size: 12px;
    margin: 5px;
    font-weight: 600;
}

.hr-coupon-modal {
    background: #FDDB3A;
    height: 1px;
}

.coupon-modal-instruction {
    font-weight: 600;
    width: 250px;
    font-size: 12px;
    color: black;
    margin: 6px auto;
}

.report-coupon-modal {
    text-decoration: underline;
    font-size: 11px;
}

.coupon-helwa-img {
    width: 16px !important;
    height: 16px !important;
}

.copy-code-btn {
    background: #f6c70b;
    font-weight: 600;
    color: black;
}

.pointer-arrow {
    cursor: pointer;
}

.btn-primary {
    color: #fff !important;
}

.form-control:disabled,
.form-control[readonly] {
    background-color: white !important;
}

.deal-expires {
    font-size: 13px;
    font-weight: 600;
    padding-top: 4px;
}

.nxt-cr {
    right: 0px !important;
}

.prv-cr {
    left: 0px;
    z-index: 999;
}

@media only screen and (max-width: 767px) {
    .prv-cr {
        left: 0px !important;
        z-index: 999;
    }
}

.shop-search-input {
    border: 1px solid grey !important;
    width: 90% !important;
    text-align: left !important;
    padding: 7px 32px !important;
    margin: 0px !important;
    border-radius: 0px !important;
}

.shop-search-input:focus {
    border: 1px solid grey !important;
}

.serach-btn {
    border-radius: 0px;
    margin-left: 10px;
}